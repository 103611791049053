<template>
  <a v-if="isPopup" @click="isPopup = false" class="close">×</a>
  <div class="popup" v-if="isPopup" v-html="text"></div>
  <div class="overlay-top"></div>
  <div class="overlay-bottom"></div>
  <button @click="resetAll" class="reset">🉐</button>
  <!-- <button @click="resetAll" class="reset">🕳️</button> -->
  <div class="container">
    <div
      class="element"
      v-for="item in randomedArray"
      @click="openPopup(item.opis)"
    >
      {{ item.typ == "motywator" ? "✉️" : "🎁" }}
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "App",
  setup() {
    const randomedArray = ref([]);
    const isPopup = ref(false);
    const text = ref("");
    // const isActive = ref(false);
    let tempArray = [];

    const motywatory = [
      {
        opis: "Motywacja jest tym co pozwala Ci&nbsp;zacząć. Nawyk jest tym co&nbsp;pozwala Ci&nbsp;wytrwać!",
        typ: "motywator",
        autor: "",
      },
      {
        opis: "Zajebista robota, widać postęp - działaj dalej!",
        typ: "motywator",
        autor: "",
      },
      {
        opis: "Nigdy nie rezygnuj z&nbsp;celu tylko dlatego, że&nbsp;osiągnięcie go wymaga czasu. Czas i&nbsp;tak upłynie.",
        typ: "motywator",
        autor: "",
      },
      {
        opis: "Sposobem na zaczęcie jest skończenie mówienia i&nbsp;podjęcie działania.",
        typ: "motywator",
        autor: "",
      },
      {
        opis: "Starania i&nbsp;odwaga nie wystarczą, jeżeli zabraknie celu i&nbsp;ukierunkowania.",
        typ: "motywator",
        autor: "",
      },
      {
        opis: "Aby samemu stać się lepszym, nie musisz czekać na&nbsp;lepszy świat.",
        typ: "motywator",
        autor: "",
      },
      {
        opis: "Bez względu na to ile popełnisz błędów, albo jak wolno idą postępy, i&nbsp;tak jesteś przed tymi, którzy nie próbują wcale!",
        typ: "motywator",
        autor: "",
      },
      {
        opis: "Wszystkie nasze marzenia mogą stać się rzeczywistością, jeśli mamy odwagę je&nbsp;realizować. ",
        typ: "motywator",
        autor: "",
      },
      {
        opis: "Ludzie, którzy na tym świecie kroczą naprzód, wstają i&nbsp;szukają odpowiednich warunków, a&nbsp;jeśli ich nie mogą znaleźć, tworzą je. ",
        typ: "motywator",
        autor: "",
      },
      {
        opis: "Żadne zadanie nie jest szczególnie trudne, jeśli podzielisz je&nbsp;na mniejsze podzadania.",
        typ: "motywator",
      },
      {
        opis: "Ilekroć znajdziesz się po&nbsp;stronie większości zastanów się przez chwilę.",
        typ: "motywator",
        autor: "Mark Twain",
      },
      {
        opis: "Punktem wyjścia wszystkich osiągnięć jest pragnienie.",
        typ: "motywator",
        autor: "Napoleon Hill",
      },
      {
        opis: "Wszelki postęp dzieje się poza strefą komfortu.",
        typ: "motywator",
        autor: "Michael John Bobak",
      },
      {
        opis: "Mogę zaakceptować porażkę, ale nie mogę zaakceptować braku próby.",
        typ: "motywator",
        autor: "Michael Jordan",
      },
      {
        opis: "Wielki wojownik, to zwykły człowiek z laserowym skupieniem.",
        typ: "motywator",
        autor: "Bruce Lee",
      },
    ];

    const nagrody = [
      { opis: "💥<br>Bosko TuThai - żeberka? :)", typ: "nagroda" },
      { opis: "🦊<br>Śniadanie u Waszyngtona", typ: "nagroda" },
      { opis: "🐷<br>TukTuk, coś dobrego :D", typ: "nagroda" },
      { opis: "🐿️<br> nagroda 4", typ: "nagroda" },
      { opis: "🐤<br> Silk & Spicy, może Mango Lime?", typ: "nagroda" },
      { opis: "🐠<br> NOWA Książka jest Twoja!", typ: "nagroda" },
      { opis: "🐸<br> nagroda 7", typ: "nagroda" },
    ];

    function random_item(items) {
      return items[Math.floor(Math.random() * items.length)];
    }

    const fillTheArray = (numOfIttems, sourceArr, targetArr) => {
      for (let i = 0; i < numOfIttems; i++) {
        targetArr.push(random_item(sourceArr));
      }
    };

    const shuffleArray = (arr) => arr.sort(() => 0.5 - Math.random());

    const chooseRandomly = () => {
      fillTheArray(20, nagrody, tempArray);
      tempArray[0].typ = "motywator"; // zmiana typu z nagrody na motywator zeby czasem nawet pod koperta znajdowala sie nagroda
      fillTheArray(100, motywatory, tempArray);

      // elWylosowany.value = random_item(tempArray);
      console.log(tempArray);
      console.log(shuffleArray(tempArray));

      randomedArray.value = shuffleArray(tempArray);
      console.log(randomedArray.value);
    };
    chooseRandomly();

    const resetAll = () => {
      let numOfElements = randomedArray.value.length;
      let elementHeight = 115; //px
      let randomHeight = Math.random() * (numOfElements * elementHeight + 1000);

      document.querySelector(".container").scrollTo({
        top: randomHeight,
        left: 0,
        behavior: "smooth",
      });

      //
      // randomedArray.value = ref([]);
      // tempArray = [];
      // window.location.reload(true);
    };

    const openPopup = (opis) => {
      text.value = opis;
      isPopup.value = true;
    };

    return {
      chooseRandomly,
      resetAll,
      randomedArray,
      openPopup,
      text,
      isPopup,
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  padding: 0px;
  margin: 0px;
}
html {
  scroll-behavior: smooth;
}
body {
  padding: 0px;
  margin: 0px;
  overflow-y: hidden;
}
.container {
  background-color: #ff6600;
  height: 100vh;
  padding: 0px;
  margin: 0px;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
}
.wrapper {
  width: 100%;
}

.element {
  scroll-snap-stop: always;
  scroll-snap-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  text-align: center;
  border-top: 3px solid rgb(143, 64, 0);
  height: 115px;
  width: 100%;
  cursor: pointer;
  color: white;
  font-size: 3rem;
}

.element:first-of-type {
  margin-top: 500px;
}
.element:last-of-type {
  margin-bottom: 500px;
}

.reset {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 25px;
  z-index: 50;
  font-size: 3rem;
  background: transparent;
  border: none;
  transition: all 0.4s ease-in-out;
}

.reset:hover {
  cursor: pointer;
  bottom: 50px;
}
.overlay-top {
  z-index: 10;
  width: 100%;
  height: calc(50vh - 59px);
  /* background: red; */

  position: fixed;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.8) 50%,
    rgba(0, 0, 0, 0.5) 100%
  );
}
.overlay-bottom {
  z-index: 10;
  width: 100%;
  height: calc(50vh - 60px);
  background: red;

  position: fixed;
  bottom: 0px;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.8) 50%,
    rgba(0, 0, 0, 0.5) 100%
  );
}
.popup {
  width: 100%;
  height: 100vh;
  z-index: 99;
  background-color: #ff6600;
  color: black;
  font-size: 2rem;

  position: fixed;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.close {
  z-index: 100;
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  width: 39px;
  height: 39px;
  line-height: 39px;
  border: 2px solid black;
  background-color: transparent;
  border-radius: 100px;
  color: black;
  justify-content: center;
}
.close:hover {
  border-color: white;
  color: whitesmoke;
  cursor: pointer;
  transition-duration: 500ms;
}
</style>
